import { Box, PopoverOrigin, ThemeOptions } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, SxProps, ThemeProvider } from '@mui/material/styles';
import { Children, cloneElement, FC, isValidElement, useCallback, useMemo, useRef, useState } from 'react';
import { fontFamilies } from '../../utils/fonts';

export type MenuItemProps = {
    itemName: string | JSX.Element;
    callback?: (event: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
};

export type ETFMenuProps = {
    children: React.ReactNode;
    menuItems?: Array<MenuItemProps>;
    MenuItemsComponent?: FC<{ handleClose: () => void }>;
    menuStyles?: SxProps;
    menuItemStyles?: SxProps;
    paperProps?: SxProps;
    selectedItemIndex?: number;
    onHoverItemStyles?: SxProps;
    addBorderBetweenItems?: boolean;
    transformOrigin?: PopoverOrigin;
    anchorOrigin?: PopoverOrigin;
    keepOpen?: boolean;
    useSelectedIndex?: boolean;
    useClickableBox?: boolean;
    themeOptions?: ThemeOptions;
};

const itemSelectedStyles: SxProps = {
    background: '#FFF !important',
    color: '#007BB8',
    borderLeft: 'solid #007BBB 4px !important',
};

export function ETFMenu(props: ETFMenuProps) {
    const {
        children,
        menuItems,
        MenuItemsComponent,
        menuStyles = {},
        menuItemStyles = {},
        paperProps = {},
        selectedItemIndex,
        onHoverItemStyles,
        addBorderBetweenItems = true,
        transformOrigin,
        anchorOrigin,
        keepOpen = false,
        useSelectedIndex = true,
        useClickableBox,
        themeOptions,
    } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget as HTMLElement);
    }, []);

    const mappedChildren = useMemo(() => {
        if (useClickableBox) {
            return null;
        }

        return Children.toArray(children).map((child, index) => {
            if (isValidElement(child)) {
                const childWithOnclick = child as React.ReactElement<
                    JSX.Element['type'],
                    JSX.Element['props'] & { onClick: (event: React.MouseEvent<HTMLElement>) => void }
                >;
                return cloneElement(childWithOnclick, { onClick: handleClick });
            }
            return null;
        });
    }, [children, handleClick, useClickableBox]);

    const handleClose = useCallback(() => {
        if (useClickableBox) {
            setOpenClickableBox(false);
            return;
        }

        setAnchorEl(null);
    }, [useClickableBox]);

    const anchorElRef = useRef<HTMLDivElement>(null);
    const [openClickableBox, setOpenClickableBox] = useState(false);

    const clickableBox = useMemo(() => {
        if (!useClickableBox) {
            return null;
        }

        return (
            <Box ref={anchorElRef} onClick={() => setOpenClickableBox(true)}>
                {children}
            </Box>
        );
    }, [children, useClickableBox]);

    const theme = useMemo(() => {
        return createTheme(
            createTheme(),
            themeOptions || {
                components: {
                    MuiMenu: {
                        styleOverrides: {
                            root: {
                                zIndex: 3000,
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                                marginTop: 5.5,
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    zIndex: 0,
                                },
                                '.MuiPopover-paper': {
                                    ...paperProps,
                                },
                                ul: {
                                    paddingTop: '0px',
                                    paddingBottom: '0px',
                                    ...menuStyles,
                                },
                            },
                        },
                    },
                    MuiMenuItem: {
                        styleOverrides: {
                            root: {
                                fontFamily: fontFamilies.GraphikRegular,
                                fontSize: '14px',
                                lineHeight: 1,
                                color: '#57626a',
                                padding: '12px 20px 12px 24px',
                                borderLeft: 'solid #fff 4px',
                                '&:hover': {
                                    background: '#FFF',
                                    color: '#007BB8',
                                    borderLeft: 'solid #007BBB 4px',
                                    ...onHoverItemStyles,
                                },
                                ...(addBorderBetweenItems
                                    ? {
                                          ':not(:last-child)': {
                                              borderBottom: 'solid #4375BB 1px',
                                          },
                                      }
                                    : {}),
                                ...menuItemStyles,
                            },
                        },
                    },
                },
            },
        );
    }, [menuStyles, menuItemStyles, paperProps, onHoverItemStyles, addBorderBetweenItems, themeOptions]);

    return (
        <>
            {mappedChildren}
            {clickableBox}
            <ThemeProvider theme={theme}>
                <Menu
                    anchorEl={useClickableBox ? anchorElRef.current : anchorEl}
                    id='account-menu'
                    open={useClickableBox ? openClickableBox : Boolean(anchorEl)}
                    onClose={handleClose}
                    onClick={keepOpen ? () => {} : handleClose}
                    transformOrigin={transformOrigin || { horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={anchorOrigin || { horizontal: 'right', vertical: 'bottom' }}
                    transitionDuration={{ enter: 0, exit: 75 }}
                    PaperProps={{
                        elevation: 0,
                    }}>
                    {menuItems?.map(({ itemName, callback = () => {}, disabled = false }, index) => {
                        return (
                            <MenuItem
                                onClick={(e) => callback(e)}
                                key={index}
                                className={'data-cy-etf-menu-option'}
                                disabled={disabled}
                                component='div'
                                {...(useSelectedIndex
                                    ? {
                                          selected: index === selectedItemIndex,
                                          sx: index === selectedItemIndex ? itemSelectedStyles : {},
                                      }
                                    : undefined)}>
                                {itemName}
                            </MenuItem>
                        );
                    })}
                    {MenuItemsComponent && <MenuItemsComponent handleClose={handleClose} />}
                </Menu>
            </ThemeProvider>
        </>
    );
}
